/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The LogEntryResponse model module.
 * @module model/LogEntryResponse
 * @version 0.0.1
 */
class LogEntryResponse {
    /**
     * Constructs a new <code>LogEntryResponse</code>.
     * @alias module:model/LogEntryResponse
     */
    constructor() { 
        
        LogEntryResponse.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>LogEntryResponse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/LogEntryResponse} obj Optional instance to populate.
     * @return {module:model/LogEntryResponse} The populated <code>LogEntryResponse</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LogEntryResponse();

            if (data.hasOwnProperty('pageCount')) {
                obj['pageCount'] = ApiClient.convertToType(data['pageCount'], 'Number');
            }
            if (data.hasOwnProperty('entries')) {
                obj['entries'] = ApiClient.convertToType(data['entries'], [Object]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>LogEntryResponse</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>LogEntryResponse</code>.
     */
    static validateJSON(data) {
        // ensure the json data is an array
        if (!Array.isArray(data['entries'])) {
            throw new Error("Expected the field `entries` to be an array in the JSON data but got " + data['entries']);
        }

        return true;
    }


}



/**
 * @member {Number} pageCount
 */
LogEntryResponse.prototype['pageCount'] = undefined;

/**
 * @member {Array.<Object>} entries
 */
LogEntryResponse.prototype['entries'] = undefined;






export default LogEntryResponse;

