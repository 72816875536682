/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import MachineFileFixed from './MachineFileFixed';
import MachineFileStatic from './MachineFileStatic';

/**
 * The MachineFile model module.
 * @module model/MachineFile
 * @version 0.0.1
 */
class MachineFile {
    /**
     * Constructs a new <code>MachineFile</code>.
     * @alias module:model/MachineFile
     */
    constructor() { 
        
        MachineFile.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>MachineFile</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MachineFile} obj Optional instance to populate.
     * @return {module:model/MachineFile} The populated <code>MachineFile</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MachineFile();

            if (data.hasOwnProperty('uuid')) {
                obj['uuid'] = ApiClient.convertToType(data['uuid'], 'String');
            }
            if (data.hasOwnProperty('version')) {
                obj['version'] = ApiClient.convertToType(data['version'], 'Number');
            }
            if (data.hasOwnProperty('timestamp')) {
                obj['timestamp'] = ApiClient.convertToType(data['timestamp'], 'Date');
            }
            if (data.hasOwnProperty('static')) {
                obj['static'] = MachineFileStatic.constructFromObject(data['static']);
            }
            if (data.hasOwnProperty('fixed')) {
                obj['fixed'] = MachineFileFixed.constructFromObject(data['fixed']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>MachineFile</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MachineFile</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['uuid'] && !(typeof data['uuid'] === 'string' || data['uuid'] instanceof String)) {
            throw new Error("Expected the field `uuid` to be a primitive type in the JSON string but got " + data['uuid']);
        }
        // validate the optional field `static`
        if (data['static']) { // data not null
          MachineFileStatic.validateJSON(data['static']);
        }
        // validate the optional field `fixed`
        if (data['fixed']) { // data not null
          MachineFileFixed.validateJSON(data['fixed']);
        }

        return true;
    }


}



/**
 * @member {String} uuid
 */
MachineFile.prototype['uuid'] = undefined;

/**
 * @member {Number} version
 */
MachineFile.prototype['version'] = undefined;

/**
 * @member {Date} timestamp
 */
MachineFile.prototype['timestamp'] = undefined;

/**
 * @member {module:model/MachineFileStatic} static
 */
MachineFile.prototype['static'] = undefined;

/**
 * @member {module:model/MachineFileFixed} fixed
 */
MachineFile.prototype['fixed'] = undefined;






export default MachineFile;

