/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import DeviceLogsResponse from '../model/DeviceLogsResponse';
import Error from '../model/Error';

/**
* DeviceLogs service.
* @module api/DeviceLogsApi
* @version 0.0.1
*/
export default class DeviceLogsApi {

    /**
    * Constructs a new DeviceLogsApi. 
    * @alias module:api/DeviceLogsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the getAllDeviceLogs operation.
     * @callback module:api/DeviceLogsApi~getAllDeviceLogsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/DeviceLogsResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} [filter] 
     * @param {String} [sortBy] 
     * @param {Boolean} [sortDesc] 
     * @param {Number} [currentPage] 
     * @param {Number} [perPage] 
     * @param {module:api/DeviceLogsApi~getAllDeviceLogsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/DeviceLogsResponse}
     */
    getAllDeviceLogs(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'filter': opts['filter'],
        'sortBy': opts['sortBy'],
        'sortDesc': opts['sortDesc'],
        'currentPage': opts['currentPage'],
        'perPage': opts['perPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = DeviceLogsResponse;
      return this.apiClient.callApi(
        '/admin/device-logs/data', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
