<template>
  <div>
    <div class="card">
      <div class="card-header d-flex align-items-center justify-content-between">
        <span>Media</span>
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <div class="card m-auto">
            <div class="card-header d-flex align-items-center justify-content-between">
              <span>Top deck</span>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="float-left">
                  Media type
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.topDeck.type" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.topDeck.type }}</span>
                </div>
              </li>
              <li class="list-group-item" v-if="shouldShowTopDeckCompetitor">
                <div class="float-left">
                  Competitor name
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.topDeck.competitorName" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.topDeck.competitorName }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Media type specificity
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.topDeck.typeSpecificity" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.topDeck.typeSpecificity }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Material
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.topDeck.material" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.topDeck.material }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Opening shape
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.topDeck.openingShape" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.topDeck.openingShape }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Opening size (mm)
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.topDeck.openingSize" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.topDeck.openingSize }}</span>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li class="list-group-item">
          <div class="card m-auto" v-if="shouldShowUpperMidDeck">
            <div class="card-header d-flex align-items-center justify-content-between">
              <span>Upper mid deck</span>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="float-left">
                  Media type
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.upperMidDeck.type" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.upperMidDeck.type }}</span>
                </div>
              </li>
              <li class="list-group-item" v-if="shouldShowUpperMidDeckCompetitor">
                <div class="float-left">
                  Competitor name
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.upperMidDeck.competitorName" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.upperMidDeck.competitorName }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Media type specificity
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.upperMidDeck.typeSpecificity" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.upperMidDeck.typeSpecificity }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Material
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.upperMidDeck.material" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.upperMidDeck.material }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Opening shape
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.upperMidDeck.openingShape" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.upperMidDeck.openingShape }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Opening size (mm)
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.upperMidDeck.openingSize" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.upperMidDeck.openingSize }}</span>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li class="list-group-item">
          <div class="card m-auto" v-if="shouldShowLowerMidDeck">
            <div class="card-header d-flex align-items-center justify-content-between">
              <span>Lower mid deck</span>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="float-left">
                  Media type
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.lowerMidDeck.type" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.lowerMidDeck.type }}</span>
                </div>
              </li>
              <li class="list-group-item" v-if="shouldShowLowerMidDeckCompetitor">
                <div class="float-left">
                  Competitor name
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.lowerMidDeck.competitorName" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.lowerMidDeck.competitorName }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Media type specificity
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.lowerMidDeck.typeSpecificity" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.lowerMidDeck.typeSpecificity }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Material
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.lowerMidDeck.material" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.lowerMidDeck.material }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Opening shape
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.lowerMidDeck.openingShape" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.lowerMidDeck.openingShape }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Opening size (mm)
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.lowerMidDeck.openingSize" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.lowerMidDeck.openingSize }}</span>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li class="list-group-item">
          <div class="card m-auto" v-if="shouldShowBottomDeck">
            <div class="card-header d-flex align-items-center justify-content-between">
              <span>Bottom deck</span>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="float-left">
                  Media type
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.bottomDeck.type" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.bottomDeck.type }}</span>
                </div>
              </li>
              <li class="list-group-item" v-if="shouldShowBottomDeckCompetitor">
                <div class="float-left">
                  Competitor name
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.bottomDeck.competitorName" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.bottomDeck.competitorName }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Media type specificity
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.bottomDeck.typeSpecificity" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.bottomDeck.typeSpecificity }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Material
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.bottomDeck.material" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.bottomDeck.material }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Opening shape
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.bottomDeck.openingShape" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.bottomDeck.openingShape }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Opening size (mm)
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.bottomDeck.openingSize" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.bottomDeck.openingSize }}</span>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MediaData',
  props: {
    data: {
      type: Object
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      machineFile: { ...this.data }
    };
  },
  methods: {
    emitChange() {
      this.$emit('onChange', this.machineFile);
    }
  },
  components: {},
  computed: {
    shouldShowTopDeckCompetitor() {
      return this.machineFile.fixed.media.topDeck.type?.toLowerCase() === 'competitor'
    },
    shouldShowUpperMidDeckCompetitor() {
      return this.machineFile.fixed.media.upperMidDeck.type?.toLowerCase() === 'competitor'
    },
    shouldShowLowerMidDeckCompetitor() {
      return this.machineFile.fixed.media.lowerMidDeck.type?.toLowerCase() === 'competitor'
    },
    shouldShowBottomDeckCompetitor() {
      return this.machineFile.fixed.media.bottomDeck.type?.toLowerCase() === 'competitor'
    },
    shouldShowUpperMidDeck() {
      return this.machineFile.static.decksAmount > 2
    },
    shouldShowLowerMidDeck() {
      return this.machineFile.static.decksAmount > 3
    },
    shouldShowBottomDeck() {
      return this.machineFile.static.decksAmount > 1
    }
  }
}
</script>

<style lang="scss">
</style>
