<template>
  <div class="card">
    <div class="card-header d-flex align-items-center justify-content-between">
      <span>Motor</span>
    </div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <div class="float-left">
          Motor frequency
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.motorFrequency" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.motorFrequency }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Expected speed (RPM)
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.expectedSpeed" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.expectedSpeed }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Rotation direction
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.rotationDirection" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.rotationDirection }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          VSD/VSF installed?
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.vsdvfdInstalled" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.vsdvfdInstalled }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Electronic brake installed?
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.electronicBrakeInstalled" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.electronicBrakeInstalled }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Motor sheave diameter as specified in factory docs/GA?
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.motorSheaveDiameterAsSpecified" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.motorSheaveDiameterAsSpecified }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Driven sheave diameter as specified in factory docs/GA?
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.drivenSheaveDiameterAsSpecified" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.drivenSheaveDiameterAsSpecified }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="card m-auto">
          <div class="card-header d-flex align-items-center justify-content-between">
            <span>Motor A</span>
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <div class="float-left">
                Type
              </div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.motorA.type" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.motor.motorA.type }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">
                Manufacturer
              </div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.motorA.manufacturer" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.motor.motorA.manufacturer }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">
                Serial
              </div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.motorA.serial" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.motor.motorA.serial }}</span>
              </div>
            </li>
          </ul>
        </div>
      </li>
      <li class="list-group-item">
        <div class="card m-auto">
          <div class="card-header d-flex align-items-center justify-content-between">
            <span>Motor B</span>
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <div class="float-left">
                Type
              </div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.motorB.type" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.motor.motorB.type }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">
                Manufacturer
              </div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.motorB.manufacturer" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.motor.motorB.manufacturer }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">
                Serial
              </div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.motorB.serial" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.motor.motorB.serial }}</span>
              </div>
            </li>
          </ul>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Exciter serial no.
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.exciterSerials[0]" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.exciterSerials[0] }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Location
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.exciterLocations[0]" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.exciterLocations[0] }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Exciter serial no.
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.exciterSerials[1]" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.exciterSerials[1] }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Location
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.exciterLocations[1]" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.exciterLocations[1] }}</span>
        </div>
      </li>
      <li class="list-group-item" v-if="shouldShowExciter2Plus">
        <div class="float-left">
          Exciter serial no.
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.exciterSerials[2]" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.exciterSerials[2] }}</span>
        </div>
      </li>
      <li class="list-group-item" v-if="shouldShowExciter2Plus">
        <div class="float-left">
          Location
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.exciterLocations[2]" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.exciterLocations[2] }}</span>
        </div>
      </li>
      <li class="list-group-item" v-if="shouldShowExciter3Plus">
        <div class="float-left">
          Exciter serial no.
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.exciterSerials[3]" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.exciterSerials[3] }}</span>
        </div>
      </li>
      <li class="list-group-item" v-if="shouldShowExciter3Plus">
        <div class="float-left">
          Location
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.exciterLocations[3]" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.exciterLocations[3] }}</span>
        </div>
      </li>
      <li class="list-group-item" v-if="shouldShowExciter4Plus">
        <div class="float-left">
          Exciter serial no.
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.exciterSerials[4]" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.exciterSerials[4] }}</span>
        </div>
      </li>
      <li class="list-group-item" v-if="shouldShowExciter4Plus">
        <div class="float-left">
          Location
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.exciterLocations[4]" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.exciterLocations[4] }}</span>
        </div>
      </li>
      <li class="list-group-item" v-if="shouldShowExciter4Plus">
        <div class="float-left">
          Exciter serial no.
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.exciterSerials[5]" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.exciterSerials[5] }}</span>
        </div>
      </li>
      <li class="list-group-item" v-if="shouldShowExciter4Plus">
        <div class="float-left">
          Location
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.exciterLocations[5]" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.exciterLocations[5] }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'MotorData',
  props: {
    data: {
      type: Object
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      machineFile: { ...this.data }
    };
  },
  methods: {
    emitChange() {
      this.$emit('onChange', this.machineFile);
    }
  },
  components: {},
  computed: {
    shouldShowExciter2Plus() {
      return this.machineFile.static.excitersAmount > 2
    },
    shouldShowExciter3Plus() {
      return this.machineFile.static.excitersAmount > 3
    },
    shouldShowExciter4Plus() {
      return this.machineFile.static.excitersAmount > 4
    }
  }
}
</script>

<style lang="scss">
</style>
