/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Error from '../model/Error';
import MachineFile from '../model/MachineFile';

/**
* MachineFiles service.
* @module api/MachineFilesApi
* @version 0.0.1
*/
export default class MachineFilesApi {

    /**
    * Constructs a new MachineFilesApi. 
    * @alias module:api/MachineFilesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the getMachineFilesOpenForReview operation.
     * @callback module:api/MachineFilesApi~getMachineFilesOpenForReviewCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/MachineFile>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} machineSerial The serial of the machine to return
     * @param {module:api/MachineFilesApi~getMachineFilesOpenForReviewCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/MachineFile>}
     */
    getMachineFilesOpenForReview(machineSerial, callback) {
      let postBody = null;
      // verify the required parameter 'machineSerial' is set
      if (machineSerial === undefined || machineSerial === null) {
        throw new Error("Missing the required parameter 'machineSerial' when calling getMachineFilesOpenForReview");
      }

      let pathParams = {
        'machineSerial': machineSerial
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [MachineFile];
      return this.apiClient.callApi(
        '/machine-file/{machineSerial}/review/data', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the markMachineFileAsReviewed operation.
     * @callback module:api/MachineFilesApi~markMachineFileAsReviewedCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} uuid The machine file uuid that has been reviewed
     * @param {Object} opts Optional parameters
     * @param {Object} [body] 
     * @param {module:api/MachineFilesApi~markMachineFileAsReviewedCallback} callback The callback function, accepting three arguments: error, data, response
     */
    markMachineFileAsReviewed(uuid, opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling markMachineFileAsReviewed");
      }

      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/machine-file/{uuid}/review/accept', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
