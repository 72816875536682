<template>
  <div>
    <b-container>
      <b-row>
        <h2 class="page-header mr-auto">{{ stat_title }}</h2>
        <div class="align-self-center">
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text>
                <font-awesome-icon icon="search"/>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input v-model="filter" debounce="500" placeholder="Search" type="text"></b-form-input>
          </b-input-group>
        </div>
      </b-row>
    </b-container>
    <b-table
        id="stats-table"
        :busy.sync="isBusy"
        :current-page="currentPage"
        :filter="filter"
        :items="stats"
        :per-page="perPage"
        :responsive="true"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        show-empty
        small
        striped
        @filtered="onFiltered"
    >
      <template #empty="">
        <div aria-live="polite" role="alert">
          <div class="text-center my-2">
            <b-spinner v-if="isBusy" :style="'visibility: ' + (isBusy ? 'visible': 'hidden')" label="Loading..."
                       variant="secondary"></b-spinner>
            <span v-else>There are no records matching your request</span>
          </div>
        </div>
      </template>
      <template #emptyfiltered="">
        <div aria-live="polite" role="alert">
          <div class="text-center my-2">
            <span>There are no records matching your request</span>
          </div>
        </div>
      </template>
    </b-table>
    <div v-if="statusText" class="mb-3">
      {{ statusText }}
    </div>
    <div class="d-flex justify-content-between align-items-center mb-3">
		<span class="text-muted">
			{{ totalRows }} results
			<b-spinner :style="'visibility: ' + (isBusy ? 'visible': 'hidden')" label="Loading..." small
                 variant="secondary"></b-spinner>
		</span>
      <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          aria-controls="stats-table"
          class="mb-0"
          first-number
          last-number
          limit="7"
          size="sm"
      ></b-pagination>
      <b-form inline>
        <span class="text-muted">Show</span>
        <b-form-select v-model="perPage" :options="perPageOptions" class="ml-2" size="sm"></b-form-select>
      </b-form>
    </div>
  </div>
</template>

<script>
import { statsApi } from '@/backend'

export default {
  name: 'StatsTable',
  props: [
    'stat_title',
    'stat_key'
  ],
  data () {
    return {
      stats: [],
      statusText: null,
      totalRows: 0,
      isBusy: false,
      filter: '',
      sortBy: null,
      sortDesc: false,
      perPage: 25,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100]
    }
  },
  created: function () {
    this.fetchStats()
  },
  methods: {
    fetchStats: function () {
      this.isBusy = true
      statsApi.getStatData(this.stat_key, (error, data) => {
        if (error) {
          console.log(error)
          this.statusText = 'Error transferring stats'
          this.isBusy = false
          return
        }
        this.stats = data
        this.totalRows = this.stats.length
        this.statusText = null
        this.isBusy = false
      })
    },
    onFiltered: function (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  }
}
</script>

<style>
</style>
