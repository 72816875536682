/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The MachineFileStaticData model module.
 * @module model/MachineFileStaticData
 * @version 0.0.1
 */
class MachineFileStaticData {
    /**
     * Constructs a new <code>MachineFileStaticData</code>.
     * @alias module:model/MachineFileStaticData
     */
    constructor() { 
        
        MachineFileStaticData.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>MachineFileStaticData</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MachineFileStaticData} obj Optional instance to populate.
     * @return {module:model/MachineFileStaticData} The populated <code>MachineFileStaticData</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MachineFileStaticData();

            if (data.hasOwnProperty('machineSerialNumber')) {
                obj['machineSerialNumber'] = ApiClient.convertToType(data['machineSerialNumber'], 'String');
            }
            if (data.hasOwnProperty('machineType')) {
                obj['machineType'] = ApiClient.convertToType(data['machineType'], 'String');
            }
            if (data.hasOwnProperty('dealerName')) {
                obj['dealerName'] = ApiClient.convertToType(data['dealerName'], 'String');
            }
            if (data.hasOwnProperty('customerEquipmentCode')) {
                obj['customerEquipmentCode'] = ApiClient.convertToType(data['customerEquipmentCode'], 'String');
            }
            if (data.hasOwnProperty('customerName')) {
                obj['customerName'] = ApiClient.convertToType(data['customerName'], 'String');
            }
            if (data.hasOwnProperty('siteName')) {
                obj['siteName'] = ApiClient.convertToType(data['siteName'], 'String');
            }
            if (data.hasOwnProperty('application')) {
                obj['application'] = ApiClient.convertToType(data['application'], 'String');
            }
            if (data.hasOwnProperty('decksAmount')) {
                obj['decksAmount'] = ApiClient.convertToType(data['decksAmount'], 'Number');
            }
            if (data.hasOwnProperty('driveType')) {
                obj['driveType'] = ApiClient.convertToType(data['driveType'], 'String');
            }
            if (data.hasOwnProperty('drivePosition')) {
                obj['drivePosition'] = ApiClient.convertToType(data['drivePosition'], 'String');
            }
            if (data.hasOwnProperty('excitersAmount')) {
                obj['excitersAmount'] = ApiClient.convertToType(data['excitersAmount'], 'Number');
            }
            if (data.hasOwnProperty('mechanismType')) {
                obj['mechanismType'] = ApiClient.convertToType(data['mechanismType'], 'String');
            }
            if (data.hasOwnProperty('mechanismTypesDetails')) {
                obj['mechanismTypesDetails'] = ApiClient.convertToType(data['mechanismTypesDetails'], 'String');
            }
            if (data.hasOwnProperty('lubrication')) {
                obj['lubrication'] = ApiClient.convertToType(data['lubrication'], 'String');
            }
            if (data.hasOwnProperty('motionType')) {
                obj['motionType'] = ApiClient.convertToType(data['motionType'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>MachineFileStaticData</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MachineFileStaticData</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['machineSerialNumber'] && !(typeof data['machineSerialNumber'] === 'string' || data['machineSerialNumber'] instanceof String)) {
            throw new Error("Expected the field `machineSerialNumber` to be a primitive type in the JSON string but got " + data['machineSerialNumber']);
        }
        // ensure the json data is a string
        if (data['machineType'] && !(typeof data['machineType'] === 'string' || data['machineType'] instanceof String)) {
            throw new Error("Expected the field `machineType` to be a primitive type in the JSON string but got " + data['machineType']);
        }
        // ensure the json data is a string
        if (data['dealerName'] && !(typeof data['dealerName'] === 'string' || data['dealerName'] instanceof String)) {
            throw new Error("Expected the field `dealerName` to be a primitive type in the JSON string but got " + data['dealerName']);
        }
        // ensure the json data is a string
        if (data['customerEquipmentCode'] && !(typeof data['customerEquipmentCode'] === 'string' || data['customerEquipmentCode'] instanceof String)) {
            throw new Error("Expected the field `customerEquipmentCode` to be a primitive type in the JSON string but got " + data['customerEquipmentCode']);
        }
        // ensure the json data is a string
        if (data['customerName'] && !(typeof data['customerName'] === 'string' || data['customerName'] instanceof String)) {
            throw new Error("Expected the field `customerName` to be a primitive type in the JSON string but got " + data['customerName']);
        }
        // ensure the json data is a string
        if (data['siteName'] && !(typeof data['siteName'] === 'string' || data['siteName'] instanceof String)) {
            throw new Error("Expected the field `siteName` to be a primitive type in the JSON string but got " + data['siteName']);
        }
        // ensure the json data is a string
        if (data['application'] && !(typeof data['application'] === 'string' || data['application'] instanceof String)) {
            throw new Error("Expected the field `application` to be a primitive type in the JSON string but got " + data['application']);
        }
        // ensure the json data is a string
        if (data['driveType'] && !(typeof data['driveType'] === 'string' || data['driveType'] instanceof String)) {
            throw new Error("Expected the field `driveType` to be a primitive type in the JSON string but got " + data['driveType']);
        }
        // ensure the json data is a string
        if (data['drivePosition'] && !(typeof data['drivePosition'] === 'string' || data['drivePosition'] instanceof String)) {
            throw new Error("Expected the field `drivePosition` to be a primitive type in the JSON string but got " + data['drivePosition']);
        }
        // ensure the json data is a string
        if (data['mechanismType'] && !(typeof data['mechanismType'] === 'string' || data['mechanismType'] instanceof String)) {
            throw new Error("Expected the field `mechanismType` to be a primitive type in the JSON string but got " + data['mechanismType']);
        }
        // ensure the json data is a string
        if (data['mechanismTypesDetails'] && !(typeof data['mechanismTypesDetails'] === 'string' || data['mechanismTypesDetails'] instanceof String)) {
            throw new Error("Expected the field `mechanismTypesDetails` to be a primitive type in the JSON string but got " + data['mechanismTypesDetails']);
        }
        // ensure the json data is a string
        if (data['lubrication'] && !(typeof data['lubrication'] === 'string' || data['lubrication'] instanceof String)) {
            throw new Error("Expected the field `lubrication` to be a primitive type in the JSON string but got " + data['lubrication']);
        }
        // ensure the json data is a string
        if (data['motionType'] && !(typeof data['motionType'] === 'string' || data['motionType'] instanceof String)) {
            throw new Error("Expected the field `motionType` to be a primitive type in the JSON string but got " + data['motionType']);
        }

        return true;
    }


}



/**
 * @member {String} machineSerialNumber
 */
MachineFileStaticData.prototype['machineSerialNumber'] = undefined;

/**
 * @member {String} machineType
 */
MachineFileStaticData.prototype['machineType'] = undefined;

/**
 * @member {String} dealerName
 */
MachineFileStaticData.prototype['dealerName'] = undefined;

/**
 * @member {String} customerEquipmentCode
 */
MachineFileStaticData.prototype['customerEquipmentCode'] = undefined;

/**
 * @member {String} customerName
 */
MachineFileStaticData.prototype['customerName'] = undefined;

/**
 * @member {String} siteName
 */
MachineFileStaticData.prototype['siteName'] = undefined;

/**
 * @member {String} application
 */
MachineFileStaticData.prototype['application'] = undefined;

/**
 * @member {Number} decksAmount
 */
MachineFileStaticData.prototype['decksAmount'] = undefined;

/**
 * @member {String} driveType
 */
MachineFileStaticData.prototype['driveType'] = undefined;

/**
 * @member {module:model/MachineFileStaticData.DrivePositionEnum} drivePosition
 */
MachineFileStaticData.prototype['drivePosition'] = undefined;

/**
 * @member {Number} excitersAmount
 */
MachineFileStaticData.prototype['excitersAmount'] = undefined;

/**
 * @member {String} mechanismType
 */
MachineFileStaticData.prototype['mechanismType'] = undefined;

/**
 * @member {String} mechanismTypesDetails
 */
MachineFileStaticData.prototype['mechanismTypesDetails'] = undefined;

/**
 * @member {module:model/MachineFileStaticData.LubricationEnum} lubrication
 */
MachineFileStaticData.prototype['lubrication'] = undefined;

/**
 * @member {module:model/MachineFileStaticData.MotionTypeEnum} motionType
 */
MachineFileStaticData.prototype['motionType'] = undefined;





/**
 * Allowed values for the <code>drivePosition</code> property.
 * @enum {String}
 * @readonly
 */
MachineFileStaticData['DrivePositionEnum'] = {

    /**
     * value: "LEFT"
     * @const
     */
    "LEFT": "LEFT",

    /**
     * value: "CENTER"
     * @const
     */
    "CENTER": "CENTER",

    /**
     * value: "RIGHT"
     * @const
     */
    "RIGHT": "RIGHT"
};


/**
 * Allowed values for the <code>lubrication</code> property.
 * @enum {String}
 * @readonly
 */
MachineFileStaticData['LubricationEnum'] = {

    /**
     * value: "GREASE"
     * @const
     */
    "GREASE": "GREASE",

    /**
     * value: "OIL"
     * @const
     */
    "OIL": "OIL"
};


/**
 * Allowed values for the <code>motionType</code> property.
 * @enum {String}
 * @readonly
 */
MachineFileStaticData['MotionTypeEnum'] = {

    /**
     * value: "AGG_ELLIPTICAL"
     * @const
     */
    "AGG_ELLIPTICAL": "AGG_ELLIPTICAL",

    /**
     * value: "AGG_CIRCULAR"
     * @const
     */
    "AGG_CIRCULAR": "AGG_CIRCULAR",

    /**
     * value: "AGG_LINEAR"
     * @const
     */
    "AGG_LINEAR": "AGG_LINEAR",

    /**
     * value: "MNG_ELLIPTICAL"
     * @const
     */
    "MNG_ELLIPTICAL": "MNG_ELLIPTICAL",

    /**
     * value: "MNG_CIRCULAR"
     * @const
     */
    "MNG_CIRCULAR": "MNG_CIRCULAR",

    /**
     * value: "MNG_LINEAR"
     * @const
     */
    "MNG_LINEAR": "MNG_LINEAR"
};



export default MachineFileStaticData;

