<template>
  <div>
    <b-container>
      <b-row>
        <h1 id="headline" class="page-header mr-auto">Android Device Logs</h1>
        <div class="align-self-center">
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text>
                <font-awesome-icon icon="search"/>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input v-model="filter" debounce="500" placeholder="Search" type="text"></b-form-input>
          </b-input-group>
        </div>
      </b-row>
    </b-container>
    <div>
      <b-table
          id="device-logs-table"
          :busy.sync="isBusy"
          :current-page="currentPage"
          :fields="fields"
          :filter="filter"
          :items="deviceLogsProvider"
          :per-page="perPage"
          :responsive="true"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          show-empty
          small
          striped
      >
        <template #empty="">
          <div aria-live="polite" role="alert">
            <div class="text-center my-2">
              <b-spinner v-if="isBusy" :style="'visibility: ' + (isBusy ? 'visible': 'hidden')" label="Loading..."
                         variant="secondary"></b-spinner>
              <span v-else>There are no records matching your request</span>
            </div>
          </div>
        </template>
        <template #emptyfiltered="">
          <div aria-live="polite" role="alert">
            <div class="text-center my-2">
              <span>There are no records matching your request</span>
            </div>
          </div>
        </template>
      </b-table>
      <div v-if="statusText" class="mb-3">
        {{ statusText }}
      </div>
      <div class="d-flex justify-content-between align-items-center mb-3">
      <span class="text-muted">
        {{ totalRows }} results
        <b-spinner :style="'visibility: ' + (isBusy ? 'visible': 'hidden')" label="Loading..." small
                   variant="secondary"></b-spinner>
      </span>
        <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            aria-controls="device-logs-table"
            class="mb-0"
            first-number
            last-number
            limit="7"
            size="sm"
        ></b-pagination>
        <b-form inline>
          <span class="text-muted">Show</span>
          <b-form-select v-model="perPage" :options="perPageOptions" class="ml-2" size="sm"></b-form-select>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>

import { deviceLogsApi } from '@/backend'

export default {
  name: 'DeviceLogs',
  data: function () {
    return {
      statusText: null,
      totalRows: 0,
      isBusy: false,
      fields: [
        { key: 'userName', sortable: true },
        { key: 'timestamp', sortable: true },
        { key: 'tag', sortable: true },
        { key: 'message', sortable: true }
      ],
      filter: '',
      sortBy: null,
      sortDesc: false,
      perPage: 25,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100]
    }
  },
  methods: {
    deviceLogsProvider: function (ctx, callback) {
      let query = {
        filter: ctx.filter,
        sortBy: ctx.sortBy,
        sortDesc: ctx.sortDesc,
        currentPage: ctx.currentPage,
        perPage: ctx.perPage
      }
      this.isBusy = true
      deviceLogsApi.getAllDeviceLogs(query, (error, data) => {
        if (error) {
          console.error(error)
          this.statusText = 'Error transferring device logs'
          this.isBusy = false
          callback([])
          return
        }
        this.totalRows = data.totalRows
        this.statusText = null
        this.isBusy = false
        callback(data.deviceLogs)
      })
      // Required to use callback
      return null
    }
  }
}
</script>

<style lang="scss">
</style>
