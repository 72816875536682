<template>
  <div>
    <h1 class="alert-heading">Page Not Found (404)</h1>
    <b-alert show variant="danger">
      The requested page could not be found.
    </b-alert>
    <b-link to="/" variant="primary">
      Go to Homepage
    </b-link>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>
