<template>
  <div class="card">
    <div class="card-header d-flex align-items-center justify-content-between">
      <span>Suspension</span>
    </div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <div class="float-left">Isolation frame present?</div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.isolationFramePresent" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.isolationFramePresent }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">Screen suspension type</div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.screenSuspensionType" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.screenSuspensionType }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="card m-auto">
          <div class="card-header d-flex align-items-center justify-content-between">
            <span>Screen Suspension</span>
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <div class="float-left">Feed left inner quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.screenSuspensionFeedDischarge.feedLeftInner" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.screenSuspensionFeedDischarge.feedLeftInner }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Feed left outer quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.screenSuspensionFeedDischarge.feedLeftOuter" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.screenSuspensionFeedDischarge.feedLeftOuter }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Feed right inner quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.screenSuspensionFeedDischarge.feedRightInner" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.screenSuspensionFeedDischarge.feedRightInner }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Feed right outer quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.screenSuspensionFeedDischarge.feedRightOuter" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.screenSuspensionFeedDischarge.feedRightOuter }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Discharge left inner quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.screenSuspensionFeedDischarge.dischargeLeftInner" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.screenSuspensionFeedDischarge.dischargeLeftInner }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Discharge left outer quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.screenSuspensionFeedDischarge.dischargeLeftOuter" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.screenSuspensionFeedDischarge.dischargeLeftOuter }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Discharge right inner quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.screenSuspensionFeedDischarge.dischargeRightInner" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.screenSuspensionFeedDischarge.dischargeRightInner }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Discharge right outer quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.screenSuspensionFeedDischarge.dischargeRightOuter" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.screenSuspensionFeedDischarge.dischargeRightOuter }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Screen sideways suspension type</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.screenSuspensionFeedDischarge.screenSidewaysSuspensionType" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.screenSuspensionFeedDischarge.screenSidewaysSuspensionType }}</span>
              </div>
            </li>
          </ul>
        </div>
      </li>
      <li class="list-group-item" v-if="shouldShowIsolationFrame">
        <div class="card">
          <div class="card-header d-flex align-items-center justify-content-between">
            <span>Isolation Frame</span>
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <div class="float-left">Mounted on springs?</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.screenSuspensionFeedDischarge.isolationFrameMountedOnSuspensions" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.screenSuspensionFeedDischarge.isolationFrameMountedOnSuspensions }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Suspension type</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.isolationFrameFeedDischarge.isolationFrameSuspensionType" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.isolationFrameFeedDischarge.isolationFrameSuspensionType }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Feed left inner quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.isolationFrameFeedDischarge.feedLeftInner" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.isolationFrameFeedDischarge.feedLeftInner }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Feed left outer quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.isolationFrameFeedDischarge.feedLeftOuter" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.isolationFrameFeedDischarge.feedLeftOuter }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Feed right inner quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.isolationFrameFeedDischarge.feedRightInner" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.isolationFrameFeedDischarge.feedRightInner }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Feed right outer quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.isolationFrameFeedDischarge.feedRightOuter" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.isolationFrameFeedDischarge.feedRightOuter }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Discharge left inner quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.isolationFrameFeedDischarge.dischargeLeftInner" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.isolationFrameFeedDischarge.dischargeLeftInner }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Discharge left outer quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.isolationFrameFeedDischarge.dischargeLeftOuter" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.isolationFrameFeedDischarge.dischargeLeftOuter }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Discharge right inner quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.isolationFrameFeedDischarge.dischargeRightInner" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.isolationFrameFeedDischarge.dischargeRightInner }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Discharge right outer quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.isolationFrameFeedDischarge.dischargeRightOuter" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.isolationFrameFeedDischarge.dischargeRightOuter }}</span>
              </div>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'SuspensionData',
  props: {
    data: {
      type: Object
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      machineFile: { ...this.data }
    };
  },
  methods: {
    emitChange() {
      this.$emit('onChange', this.machineFile);
    }
  },
  components: {},
  computed: {
    shouldShowIsolationFrame() {
      return this.machineFile.fixed.isolationFramePresent.toLowerCase() === 'yes, installed'
    },
  }
}
</script>

<style lang="scss">
</style>
