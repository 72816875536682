<template>
  <b-badge variant="warning" href="#" :title="issues.toString()">
    <font-awesome-icon icon="circle-exclamation" class="mr-1"/>
    <span v-if="issues.length === 1">1 issue</span>
    <span v-else>{{ issues.length }} issues</span>
  </b-badge>
</template>

<script>

export default {
  name: 'MeasurementIssues',
  props: {
    issues: {
      type: Array
    },
  },
  components: {},
}
</script>

<style lang="scss">
</style>
