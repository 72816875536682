/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CreateMachineEntryQuery from '../model/CreateMachineEntryQuery';
import DetailedMachineInformation from '../model/DetailedMachineInformation';
import EditMachineQuery from '../model/EditMachineQuery';
import Error from '../model/Error';
import MachineFile from '../model/MachineFile';
import MachinesResponse from '../model/MachinesResponse';

/**
* Machines service.
* @module api/MachinesApi
* @version 0.0.1
*/
export default class MachinesApi {

    /**
    * Constructs a new MachinesApi. 
    * @alias module:api/MachinesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addMachines operation.
     * @callback module:api/MachinesApi~addMachinesCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Machine to add to the database
     * @param {module:model/EditMachineQuery} editMachineQuery 
     * @param {module:api/MachinesApi~addMachinesCallback} callback The callback function, accepting three arguments: error, data, response
     */
    addMachines(editMachineQuery, callback) {
      let postBody = editMachineQuery;
      // verify the required parameter 'editMachineQuery' is set
      if (editMachineQuery === undefined || editMachineQuery === null) {
        throw new Error("Missing the required parameter 'editMachineQuery' when calling addMachines");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/machines/add', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the createMachineEntry operation.
     * @callback module:api/MachinesApi~createMachineEntryCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} machineSerial The machine serial to create the machine entry
     * @param {module:model/CreateMachineEntryQuery} createMachineEntryQuery The Query containing all relevant information to create a machine entry
     * @param {module:api/MachinesApi~createMachineEntryCallback} callback The callback function, accepting three arguments: error, data, response
     */
    createMachineEntry(machineSerial, createMachineEntryQuery, callback) {
      let postBody = createMachineEntryQuery;
      // verify the required parameter 'machineSerial' is set
      if (machineSerial === undefined || machineSerial === null) {
        throw new Error("Missing the required parameter 'machineSerial' when calling createMachineEntry");
      }
      // verify the required parameter 'createMachineEntryQuery' is set
      if (createMachineEntryQuery === undefined || createMachineEntryQuery === null) {
        throw new Error("Missing the required parameter 'createMachineEntryQuery' when calling createMachineEntry");
      }

      let pathParams = {
        'machineSerial': machineSerial
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/machine/{machineSerial}/create', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the downloadFile operation.
     * @callback module:api/MachinesApi~downloadFileCallback
     * @param {String} error Error message, if any.
     * @param {Object} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} entryLongId The longid of the machine entry
     * @param {String} fileName the filename of the file to download
     * @param {module:api/MachinesApi~downloadFileCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object}
     */
    downloadFile(entryLongId, fileName, callback) {
      let postBody = null;
      // verify the required parameter 'entryLongId' is set
      if (entryLongId === undefined || entryLongId === null) {
        throw new Error("Missing the required parameter 'entryLongId' when calling downloadFile");
      }
      // verify the required parameter 'fileName' is set
      if (fileName === undefined || fileName === null) {
        throw new Error("Missing the required parameter 'fileName' when calling downloadFile");
      }

      let pathParams = {
        'entryLongId': entryLongId,
        'fileName': fileName
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/octet-stream', 'application/json'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/entry/{entryLongId}/{fileName}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the editMachine operation.
     * @callback module:api/MachinesApi~editMachineCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} machineSerial The machine serial to get the data from
     * @param {module:model/EditMachineQuery} editMachineQuery The Query containing all relevant information to edit a machine
     * @param {module:api/MachinesApi~editMachineCallback} callback The callback function, accepting three arguments: error, data, response
     */
    editMachine(machineSerial, editMachineQuery, callback) {
      let postBody = editMachineQuery;
      // verify the required parameter 'machineSerial' is set
      if (machineSerial === undefined || machineSerial === null) {
        throw new Error("Missing the required parameter 'machineSerial' when calling editMachine");
      }
      // verify the required parameter 'editMachineQuery' is set
      if (editMachineQuery === undefined || editMachineQuery === null) {
        throw new Error("Missing the required parameter 'editMachineQuery' when calling editMachine");
      }

      let pathParams = {
        'machineSerial': machineSerial
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/machine/{machineSerial}/edit', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the editMachineFile operation.
     * @callback module:api/MachinesApi~editMachineFileCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} machineSerial The serial of the machine to be edited
     * @param {module:model/EditMachineQuery} editMachineQuery 
     * @param {module:api/MachinesApi~editMachineFileCallback} callback The callback function, accepting three arguments: error, data, response
     */
    editMachineFile(machineSerial, editMachineQuery, callback) {
      let postBody = editMachineQuery;
      // verify the required parameter 'machineSerial' is set
      if (machineSerial === undefined || machineSerial === null) {
        throw new Error("Missing the required parameter 'machineSerial' when calling editMachineFile");
      }
      // verify the required parameter 'editMachineQuery' is set
      if (editMachineQuery === undefined || editMachineQuery === null) {
        throw new Error("Missing the required parameter 'editMachineQuery' when calling editMachineFile");
      }

      let pathParams = {
        'machineSerial': machineSerial
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/machine/{machineSerial}/machine-file/edit', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAllMachines operation.
     * @callback module:api/MachinesApi~getAllMachinesCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MachinesResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} [filter] 
     * @param {String} [sortBy] 
     * @param {Boolean} [sortDesc] 
     * @param {Number} [currentPage] 
     * @param {Number} [perPage] 
     * @param {module:api/MachinesApi~getAllMachinesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/MachinesResponse}
     */
    getAllMachines(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'filter': opts['filter'],
        'sortBy': opts['sortBy'],
        'sortDesc': opts['sortDesc'],
        'currentPage': opts['currentPage'],
        'perPage': opts['perPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = MachinesResponse;
      return this.apiClient.callApi(
        '/machines/data', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getMachine operation.
     * @callback module:api/MachinesApi~getMachineCallback
     * @param {String} error Error message, if any.
     * @param {module:model/DetailedMachineInformation} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} machineSerial The serial of the machine to return
     * @param {module:api/MachinesApi~getMachineCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/DetailedMachineInformation}
     */
    getMachine(machineSerial, callback) {
      let postBody = null;
      // verify the required parameter 'machineSerial' is set
      if (machineSerial === undefined || machineSerial === null) {
        throw new Error("Missing the required parameter 'machineSerial' when calling getMachine");
      }

      let pathParams = {
        'machineSerial': machineSerial
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = DetailedMachineInformation;
      return this.apiClient.callApi(
        '/machine/{machineSerial}/data', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getMachineFileDetails operation.
     * @callback module:api/MachinesApi~getMachineFileDetailsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MachineFile} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} machineSerial The serial of the machine to return
     * @param {module:api/MachinesApi~getMachineFileDetailsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/MachineFile}
     */
    getMachineFileDetails(machineSerial, callback) {
      let postBody = null;
      // verify the required parameter 'machineSerial' is set
      if (machineSerial === undefined || machineSerial === null) {
        throw new Error("Missing the required parameter 'machineSerial' when calling getMachineFileDetails");
      }

      let pathParams = {
        'machineSerial': machineSerial
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = MachineFile;
      return this.apiClient.callApi(
        '/machine/{machineSerial}/machine-file/data', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
