/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AllSensorsResponse from '../model/AllSensorsResponse';
import DeviceTokensResponse from '../model/DeviceTokensResponse';
import EditDeviceTokenQuery from '../model/EditDeviceTokenQuery';
import Error from '../model/Error';
import MySensorsResponse from '../model/MySensorsResponse';
import RevokeDeviceTokenQuery from '../model/RevokeDeviceTokenQuery';

/**
* Devices service.
* @module api/DevicesApi
* @version 0.0.1
*/
export default class DevicesApi {

    /**
    * Constructs a new DevicesApi. 
    * @alias module:api/DevicesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the getAllSensors operation.
     * @callback module:api/DevicesApi~getAllSensorsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AllSensorsResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/DevicesApi~getAllSensorsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AllSensorsResponse}
     */
    getAllSensors(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = AllSensorsResponse;
      return this.apiClient.callApi(
        '/devices/all/data', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getDeviceTokens operation.
     * @callback module:api/DevicesApi~getDeviceTokensCallback
     * @param {String} error Error message, if any.
     * @param {module:model/DeviceTokensResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/DevicesApi~getDeviceTokensCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/DeviceTokensResponse}
     */
    getDeviceTokens(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = DeviceTokensResponse;
      return this.apiClient.callApi(
        '/devices/data', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getMySensors operation.
     * @callback module:api/DevicesApi~getMySensorsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MySensorsResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/DevicesApi~getMySensorsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/MySensorsResponse}
     */
    getMySensors(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = MySensorsResponse;
      return this.apiClient.callApi(
        '/devices/my/data', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the revokeDeviceToken operation.
     * @callback module:api/DevicesApi~revokeDeviceTokenCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/RevokeDeviceTokenQuery} revokeDeviceTokenQuery The Query containing all relevant information to revoke a device token
     * @param {module:api/DevicesApi~revokeDeviceTokenCallback} callback The callback function, accepting three arguments: error, data, response
     */
    revokeDeviceToken(revokeDeviceTokenQuery, callback) {
      let postBody = revokeDeviceTokenQuery;
      // verify the required parameter 'revokeDeviceTokenQuery' is set
      if (revokeDeviceTokenQuery === undefined || revokeDeviceTokenQuery === null) {
        throw new Error("Missing the required parameter 'revokeDeviceTokenQuery' when calling revokeDeviceToken");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/token/own/revoke', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateDeviceTokenLabel operation.
     * @callback module:api/DevicesApi~updateDeviceTokenLabelCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/EditDeviceTokenQuery} editDeviceTokenQuery The Query containing all relevant information to rename a device token
     * @param {module:api/DevicesApi~updateDeviceTokenLabelCallback} callback The callback function, accepting three arguments: error, data, response
     */
    updateDeviceTokenLabel(editDeviceTokenQuery, callback) {
      let postBody = editDeviceTokenQuery;
      // verify the required parameter 'editDeviceTokenQuery' is set
      if (editDeviceTokenQuery === undefined || editDeviceTokenQuery === null) {
        throw new Error("Missing the required parameter 'editDeviceTokenQuery' when calling updateDeviceTokenLabel");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/token/own/label', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
