/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Error from '../model/Error';
import LogEntryResponse from '../model/LogEntryResponse';

/**
* Log service.
* @module api/LogApi
* @version 0.0.1
*/
export default class LogApi {

    /**
    * Constructs a new LogApi. 
    * @alias module:api/LogApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the getLogEntries operation.
     * @callback module:api/LogApi~getLogEntriesCallback
     * @param {String} error Error message, if any.
     * @param {module:model/LogEntryResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} pageNumber Returns the logs of a given page
     * @param {module:api/LogApi~getLogEntriesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/LogEntryResponse}
     */
    getLogEntries(pageNumber, callback) {
      let postBody = null;
      // verify the required parameter 'pageNumber' is set
      if (pageNumber === undefined || pageNumber === null) {
        throw new Error("Missing the required parameter 'pageNumber' when calling getLogEntries");
      }

      let pathParams = {
        'pageNumber': pageNumber
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = LogEntryResponse;
      return this.apiClient.callApi(
        '/log/data/{pageNumber}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
