/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The DeviceLogEntry model module.
 * @module model/DeviceLogEntry
 * @version 0.0.1
 */
class DeviceLogEntry {
    /**
     * Constructs a new <code>DeviceLogEntry</code>.
     * @alias module:model/DeviceLogEntry
     */
    constructor() { 
        
        DeviceLogEntry.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>DeviceLogEntry</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DeviceLogEntry} obj Optional instance to populate.
     * @return {module:model/DeviceLogEntry} The populated <code>DeviceLogEntry</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DeviceLogEntry();

            if (data.hasOwnProperty('userName')) {
                obj['userName'] = ApiClient.convertToType(data['userName'], 'String');
            }
            if (data.hasOwnProperty('userToken')) {
                obj['userToken'] = ApiClient.convertToType(data['userToken'], 'String');
            }
            if (data.hasOwnProperty('timestamp')) {
                obj['timestamp'] = ApiClient.convertToType(data['timestamp'], 'String');
            }
            if (data.hasOwnProperty('tag')) {
                obj['tag'] = ApiClient.convertToType(data['tag'], 'String');
            }
            if (data.hasOwnProperty('message')) {
                obj['message'] = ApiClient.convertToType(data['message'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>DeviceLogEntry</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>DeviceLogEntry</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['userName'] && !(typeof data['userName'] === 'string' || data['userName'] instanceof String)) {
            throw new Error("Expected the field `userName` to be a primitive type in the JSON string but got " + data['userName']);
        }
        // ensure the json data is a string
        if (data['userToken'] && !(typeof data['userToken'] === 'string' || data['userToken'] instanceof String)) {
            throw new Error("Expected the field `userToken` to be a primitive type in the JSON string but got " + data['userToken']);
        }
        // ensure the json data is a string
        if (data['timestamp'] && !(typeof data['timestamp'] === 'string' || data['timestamp'] instanceof String)) {
            throw new Error("Expected the field `timestamp` to be a primitive type in the JSON string but got " + data['timestamp']);
        }
        // ensure the json data is a string
        if (data['tag'] && !(typeof data['tag'] === 'string' || data['tag'] instanceof String)) {
            throw new Error("Expected the field `tag` to be a primitive type in the JSON string but got " + data['tag']);
        }
        // ensure the json data is a string
        if (data['message'] && !(typeof data['message'] === 'string' || data['message'] instanceof String)) {
            throw new Error("Expected the field `message` to be a primitive type in the JSON string but got " + data['message']);
        }

        return true;
    }


}



/**
 * @member {String} userName
 */
DeviceLogEntry.prototype['userName'] = undefined;

/**
 * @member {String} userToken
 */
DeviceLogEntry.prototype['userToken'] = undefined;

/**
 * @member {String} timestamp
 */
DeviceLogEntry.prototype['timestamp'] = undefined;

/**
 * @member {String} tag
 */
DeviceLogEntry.prototype['tag'] = undefined;

/**
 * @member {String} message
 */
DeviceLogEntry.prototype['message'] = undefined;






export default DeviceLogEntry;

