/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Machine model module.
 * @module model/Machine
 * @version 0.0.1
 */
class Machine {
    /**
     * Constructs a new <code>Machine</code>.
     * @alias module:model/Machine
     */
    constructor() { 
        
        Machine.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Machine</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Machine} obj Optional instance to populate.
     * @return {module:model/Machine} The populated <code>Machine</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Machine();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('serial')) {
                obj['serial'] = ApiClient.convertToType(data['serial'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'String');
            }
            if (data.hasOwnProperty('owner')) {
                obj['owner'] = ApiClient.convertToType(data['owner'], 'String');
            }
            if (data.hasOwnProperty('reportCount')) {
                obj['reportCount'] = ApiClient.convertToType(data['reportCount'], 'Number');
            }
            if (data.hasOwnProperty('fileCount')) {
                obj['fileCount'] = ApiClient.convertToType(data['fileCount'], 'Number');
            }
            if (data.hasOwnProperty('reviewCount')) {
                obj['reviewCount'] = ApiClient.convertToType(data['reviewCount'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Machine</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Machine</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['serial'] && !(typeof data['serial'] === 'string' || data['serial'] instanceof String)) {
            throw new Error("Expected the field `serial` to be a primitive type in the JSON string but got " + data['serial']);
        }
        // ensure the json data is a string
        if (data['type'] && !(typeof data['type'] === 'string' || data['type'] instanceof String)) {
            throw new Error("Expected the field `type` to be a primitive type in the JSON string but got " + data['type']);
        }
        // ensure the json data is a string
        if (data['owner'] && !(typeof data['owner'] === 'string' || data['owner'] instanceof String)) {
            throw new Error("Expected the field `owner` to be a primitive type in the JSON string but got " + data['owner']);
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
Machine.prototype['id'] = undefined;

/**
 * @member {String} serial
 */
Machine.prototype['serial'] = undefined;

/**
 * @member {String} type
 */
Machine.prototype['type'] = undefined;

/**
 * @member {String} owner
 */
Machine.prototype['owner'] = undefined;

/**
 * @member {Number} reportCount
 */
Machine.prototype['reportCount'] = undefined;

/**
 * @member {Number} fileCount
 */
Machine.prototype['fileCount'] = undefined;

/**
 * @member {Number} reviewCount
 */
Machine.prototype['reviewCount'] = undefined;






export default Machine;

