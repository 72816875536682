/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Deck model module.
 * @module model/Deck
 * @version 0.0.1
 */
class Deck {
    /**
     * Constructs a new <code>Deck</code>.
     * @alias module:model/Deck
     */
    constructor() { 
        
        Deck.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Deck</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Deck} obj Optional instance to populate.
     * @return {module:model/Deck} The populated <code>Deck</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Deck();

            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'String');
            }
            if (data.hasOwnProperty('competitorName')) {
                obj['competitorName'] = ApiClient.convertToType(data['competitorName'], 'String');
            }
            if (data.hasOwnProperty('typeSpecificity')) {
                obj['typeSpecificity'] = ApiClient.convertToType(data['typeSpecificity'], 'String');
            }
            if (data.hasOwnProperty('material')) {
                obj['material'] = ApiClient.convertToType(data['material'], 'String');
            }
            if (data.hasOwnProperty('openingSize')) {
                obj['openingSize'] = ApiClient.convertToType(data['openingSize'], 'Number');
            }
            if (data.hasOwnProperty('openingShape')) {
                obj['openingShape'] = ApiClient.convertToType(data['openingShape'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Deck</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Deck</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['type'] && !(typeof data['type'] === 'string' || data['type'] instanceof String)) {
            throw new Error("Expected the field `type` to be a primitive type in the JSON string but got " + data['type']);
        }
        // ensure the json data is a string
        if (data['competitorName'] && !(typeof data['competitorName'] === 'string' || data['competitorName'] instanceof String)) {
            throw new Error("Expected the field `competitorName` to be a primitive type in the JSON string but got " + data['competitorName']);
        }
        // ensure the json data is a string
        if (data['typeSpecificity'] && !(typeof data['typeSpecificity'] === 'string' || data['typeSpecificity'] instanceof String)) {
            throw new Error("Expected the field `typeSpecificity` to be a primitive type in the JSON string but got " + data['typeSpecificity']);
        }
        // ensure the json data is a string
        if (data['material'] && !(typeof data['material'] === 'string' || data['material'] instanceof String)) {
            throw new Error("Expected the field `material` to be a primitive type in the JSON string but got " + data['material']);
        }
        // ensure the json data is a string
        if (data['openingShape'] && !(typeof data['openingShape'] === 'string' || data['openingShape'] instanceof String)) {
            throw new Error("Expected the field `openingShape` to be a primitive type in the JSON string but got " + data['openingShape']);
        }

        return true;
    }


}



/**
 * @member {String} type
 */
Deck.prototype['type'] = undefined;

/**
 * @member {String} competitorName
 */
Deck.prototype['competitorName'] = undefined;

/**
 * @member {String} typeSpecificity
 */
Deck.prototype['typeSpecificity'] = undefined;

/**
 * @member {String} material
 */
Deck.prototype['material'] = undefined;

/**
 * @member {Number} openingSize
 */
Deck.prototype['openingSize'] = undefined;

/**
 * @member {String} openingShape
 */
Deck.prototype['openingShape'] = undefined;






export default Deck;

