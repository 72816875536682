/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Error from '../model/Error';
import StatsMeta from '../model/StatsMeta';

/**
* Stats service.
* @module api/StatsApi
* @version 0.0.1
*/
export default class StatsApi {

    /**
    * Constructs a new StatsApi. 
    * @alias module:api/StatsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the getStatData operation.
     * @callback module:api/StatsApi~getStatDataCallback
     * @param {String} error Error message, if any.
     * @param {Array.<Object>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} statKey The stat name to load the data from
     * @param {module:api/StatsApi~getStatDataCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<Object>}
     */
    getStatData(statKey, callback) {
      let postBody = null;
      // verify the required parameter 'statKey' is set
      if (statKey === undefined || statKey === null) {
        throw new Error("Missing the required parameter 'statKey' when calling getStatData");
      }

      let pathParams = {
        'stat_key': statKey
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Object];
      return this.apiClient.callApi(
        '/admin/stats/data/{stat_key}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getStatsMeta operation.
     * @callback module:api/StatsApi~getStatsMetaCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/StatsMeta>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/StatsApi~getStatsMetaCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/StatsMeta>}
     */
    getStatsMeta(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [StatsMeta];
      return this.apiClient.callApi(
        '/admin/stats/meta', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
