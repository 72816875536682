<template>
  <div>
    <b-alert class="mb-4 mt-4" show variant="secondary">
      <p>
        <font-awesome-icon class="mr-2" icon="info-circle"/>
        <strong>This is Metso's ScreenCheck Portal</strong>
      </p>
      <p class="mb-0">
        It gives you access to Screen Life Cycle data and ScreenCheck reports right from your browser.
      </p>
    </b-alert>

    <b-alert :show="!cookiesEnabled" variant="danger">
      <font-awesome-icon class="mr-2" icon="exclamation-circle"/>
      Cookies need to be enabled to access ScreenCheck Portal.
    </b-alert>

    <b-alert v-if="errorText" show variant="danger">
      {{ errorText }}
    </b-alert>

    <div v-if="isLegacyDomain">
      <h2>ScreenCheck Portal has moved</h2>
      <p>
        To access ScreenCheck Portal please visit <a href="https://screencheck.metso.com">https://screencheck.metso.com</a>.<br>
        You will be automatically redirected in 5 seconds.
      </p>
      <hr>
    </div>
    <div v-else>
      <h2>Access ScreenCheck Portal</h2>
      <p>
        To access ScreenCheck Portal please press the button below and sign in with your credentials.<br>
        After successfully signing in you will be automatically redirected to ScreenCheck Portal.
      </p>
      <b-alert variant="warning" show>
        <p>
          <font-awesome-icon class="mr-2" icon="exclamation-circle"/>
          <strong>ScreenCheck Portal transitions from Metso Login to Metso Access</strong>
        </p>
        <p class="mb-0">
          The Metso Access page has a new look but functionality and accounts remain the same. Metso employees can still sign in with their Metso Account from
          this page. External accounts have been migrated, so existing credentials can be used to log in. Please report any issues to <a
            href="mailto:screencheck@metso.com">screencheck@metso.com</a>.
        </p>
      </b-alert>
      <p>
        <b-btn href="/auth/login" variant="primary" :disabled="busy">
          <b-spinner v-if="busy" small class="mr-1"></b-spinner>
          <font-awesome-icon v-else icon="user" class="mr-1"/>
          Sign in with Metso Access
        </b-btn>
      </p>
    </div>
    <p>
      <a href="https://metso.sharepoint.com/sites/Legalandcompliance/SitePages/Privacy-Notices.aspx">Privacy Notice
        for Metso employees</a>
      -
      <a href="https://www.metso.com/legal-and-privacy/privacy-notice/">Metso’s Privacy Notice</a>
    </p>
    <p class="mt-3">
      If you have difficulties signing in please contact your ScreenCheck administrator at
      <a href="mailto:screencheck@metso.com">screencheck@metso.com</a>.
    </p>
  </div>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: 'AuthPage',
  data () {
    return {
      busy: false,
      errorText: null,
    }
  },
  computed: {
    cookiesEnabled () {
      let cookieEnabled = navigator.cookieEnabled
      if (!cookieEnabled) {
        document.cookie = 'test_cookie'
        cookieEnabled = document.cookie.indexOf('test_cookie') !== -1
      }
      return cookieEnabled
    },
    isLegacyDomain () {
      return location.hostname === 'screencheck.mogroup.com'
    },
  },
  activated () {
    if (this.isLegacyDomain) {
      setTimeout(() => location.href = 'https://screencheck.metso.com/auth', 5000)
    } else {
      this.queryTokenStatus()
    }
  },
  methods: {
    ...mapActions(['queryAuth']),
    queryTokenStatus: function () {
      this.busy = true
      this.queryAuth()
          .then(({ authenticated, response }) => {
            if (authenticated) {
              this.$router.push('/')
            } else {
              this.errorText = response && response.message
            }
          })
          .catch((error) => {
            this.errorText = error && error.message
          })
          .finally(() => {
            this.busy = false
          })
    },
  }
}
</script>
