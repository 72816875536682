/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import DeviceLogEntry from './DeviceLogEntry';

/**
 * The DeviceLogsResponse model module.
 * @module model/DeviceLogsResponse
 * @version 0.0.1
 */
class DeviceLogsResponse {
    /**
     * Constructs a new <code>DeviceLogsResponse</code>.
     * @alias module:model/DeviceLogsResponse
     */
    constructor() { 
        
        DeviceLogsResponse.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>DeviceLogsResponse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DeviceLogsResponse} obj Optional instance to populate.
     * @return {module:model/DeviceLogsResponse} The populated <code>DeviceLogsResponse</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DeviceLogsResponse();

            if (data.hasOwnProperty('deviceLogs')) {
                obj['deviceLogs'] = ApiClient.convertToType(data['deviceLogs'], [DeviceLogEntry]);
            }
            if (data.hasOwnProperty('totalRows')) {
                obj['totalRows'] = ApiClient.convertToType(data['totalRows'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>DeviceLogsResponse</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>DeviceLogsResponse</code>.
     */
    static validateJSON(data) {
        if (data['deviceLogs']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['deviceLogs'])) {
                throw new Error("Expected the field `deviceLogs` to be an array in the JSON data but got " + data['deviceLogs']);
            }
            // validate the optional field `deviceLogs` (array)
            for (const item of data['deviceLogs']) {
                DeviceLogEntry.validateJSON(item);
            };
        }

        return true;
    }


}



/**
 * @member {Array.<module:model/DeviceLogEntry>} deviceLogs
 */
DeviceLogsResponse.prototype['deviceLogs'] = undefined;

/**
 * @member {Number} totalRows
 */
DeviceLogsResponse.prototype['totalRows'] = undefined;






export default DeviceLogsResponse;

