<template>
  <div>
    <h1 id="headline" class="page-header">Log</h1>
    <div class="mb-3">
      <button :disabled="!previousPageExists" class="btn btn-outline-dark" @click="showPreviousPage">
        <font-awesome-icon icon="arrow-left"/>
        Newer
      </button>
      <button :disabled="!nextPageExists" class="btn btn-outline-dark float-right" @click="showNextPage">
        Older
        <font-awesome-icon icon="arrow-right"/>
      </button>
    </div>
    <b-table id="log-table" ref="log-table" :busy.sync="isBusy" :fields="fields" :items="logsProvider">
      <template #cell(action)="data">
        <div v-if="data.item.entry">
          <button class="btn btn-outline-dark btn-sm float-right" type="button" @click="data.toggleDetails">
            {{ data.detailsShowing ? 'Hide' : 'Show' }}
          </button>
        </div>
      </template>
      <template #row-details="data">
        <span class="json">{{ data.item.entry }}</span>
      </template>
    </b-table>
  </div>
</template>

<script>

import { logApi } from '@/backend'

export default {
  name: 'LogPage',
  components: {},
  data () {
    return {
      logs: null,
      statusText: '',
      fields: [
        { key: 'timestamp', label: 'Timestamp (UTC)' },
        { key: 'initiator', label: 'Initiator' },
        { key: 'type', label: 'Type' },
        { key: 'user', label: 'User' },
        { key: 'usertoken', label: 'User Token' },
        { key: 'report', label: 'Report' },
        { key: 'reporttoken', label: 'Report Token' },
        { key: 'action', label: 'Details' }
      ],
      currentPage: 1,
      pageCount: 0,
      isBusy: false
    }
  },
  computed: {
    previousPageExists: function () {
      return this.currentPage > 0
    },
    nextPageExists: function () {
      return this.currentPage < this.pageCount
    }
  },
  methods: {
    logsProvider: function (ctx, callback) {
      this.isBusy = true
      logApi.getLogEntries(this.currentPage, (error, data) => {
        this.isBusy = false
        if (error) {
          console.error('Failed to get log entries because: ' + error)
          this.statusText = 'Error transferring log messages'
          return
        }
        this.pageCount = data.pageCount
        callback(data.entries)
        this.statusText = null
      })

      // Must return null or undefined to signal b-table that callback is being used
      return null
    },
    showPreviousPage: function () {
      this.currentPage--
      this.$refs['log-table'].refresh()
    },
    showNextPage: function () {
      this.currentPage++
      this.$refs['log-table'].refresh()
    }
  }
}
</script>

<style lang="scss">
</style>
