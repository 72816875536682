<template>
  <div>
    <div v-for="(stat,i) in stats" :key="stat.key">
      <hr v-if="i !== 0" class="my-5">
      <StatsTable :stat_key="stat.key" :stat_title="stat.title"></StatsTable>
    </div>
  </div>
</template>

<script>
import StatsTable from '../components/StatsTable.vue'
import { statsApi } from '@/backend'

export default {
  name: 'StatsPage',
  components: {
    StatsTable
  },
  data () {
    return {
      stats: [],
      statusText: ''
    }
  },
  created () {
    this.fetchStats()
  },
  methods: {
    fetchStats: function () {
      statsApi.getStatsMeta((error, data) => {
        if (error) {
          console.error(error)
          this.statusText = 'Error transferring stats'
          return
        }
        this.stats = data
        this.statusText = null
      })
    }
  }
}
</script>

<style lang="scss">
</style>
