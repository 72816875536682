<template>
  <div>
    <div class="card">
      <div class="card-header d-flex align-items-center justify-content-between">
        Screen Life Cycle
        <div class="btn-group">
          <b-button v-b-modal="'add-entry'" size="sm" variant="outline-dark">
            <font-awesome-icon icon="plus"/>
            Add Life Cycle Entry
          </b-button>
        </div>
      </div>
      <div class="card-body">
        <div v-for="item in machine.items" :key="item.isReport ? item.report.report_longid : item.entry.longid">
          <div v-if="item.isReport">
            <div :id="item.report.report_longid" :class="defineReportEventClass(item.report.user_role)" :style="defineReportEventStyle(item.report.user_role)">
              <div class="card-header d-flex align-items-center justify-content-between">
                <a :href="'#' + item.report.report_longid">
                  <font-awesome-icon icon="list-alt"/>
                  {{ item.report.user_label }} added
                  ScreenCheck Report</a>
                <div class="btn-group float-right ml-2">
                  <b-button v-b-modal="'comment-report'" size="sm" variant="outline-dark"
                            @click="openCommentModal(item.report)">
                    <font-awesome-icon icon="comment-alt"/>
                    Comment
                  </b-button>
                  <b-button v-if="isUserAdmin" v-b-modal="'move-report' " size="sm" variant="outline-dark"
                            @click="selectedReport = item.report">
                    <font-awesome-icon icon="exchange-alt"/>
                    Move
                  </b-button>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <div v-if="item.report.report_label" title="Title">
                      <font-awesome-icon class="mr-1" icon="list-alt"/>
                      <span class="report_label">{{ item.report.report_label }}</span>
                    </div>
                    <div title="Timestamp">
                      <font-awesome-icon icon="calendar-alt"/>
                      {{ item.report.report_creation }}
                    </div>
                    <div title="Uploader" v-if="item.report.user_role === 'restricted'" class="text-warning">
                      <font-awesome-icon  icon="user-lock" title="This report has been uploaded by a restricted user"/>
                      {{ item.report.user_label }}
                    </div>
                    <div title="Uploader" v-else>
                      <font-awesome-icon icon="user"/>
                      {{ item.report.user_label }}
                    </div>
                    <div title="Technician">
                      <font-awesome-icon icon="wrench"/>
                      {{ item.report.report_author_label }}
                    </div>
                    <div v-if="item.report.report_flavor">
                      <div title="Flavor">
                        <font-awesome-icon icon="glass-martini"/>
                        {{ item.report.report_flavor }}
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div v-if="item.report.report_machine_type">
                      <div title="Screen Type">
                        <font-awesome-icon icon="tag"/>
                        {{ item.report.report_machine_type }}
                      </div>
                    </div>
                    <div v-if="item.report.report_machine_serial">
                      <div title="Screen Serial Number">
                        <font-awesome-icon icon="barcode"/>
                        {{ item.report.report_machine_serial }}
                      </div>
                    </div>
                    <div v-if="item.report.report_machine_owner_label">
                      <div title="Screen Owner">
                        <font-awesome-icon icon="briefcase"/>
                        {{ item.report.report_machine_owner_label }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="item.report.commenter_user_label">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <font-awesome-icon icon="comment-alt"/>
                    {{ item.report.commenter_user_label }} commented
                    on {{ item.report.report_comment_timestamp }}:<br>
                    <div class="current_comment" style="white-space:pre-wrap">{{ item.report.report_comment }}</div>
                  </li>
                </ul>
              </div>
              <div class="card-footer">
                <div class="row">
                  <div class="col-sm">
                    <router-link :to="'/report/' + item.report.report_longid">
                      <font-awesome-icon icon="weight"/>
                      Report page
                    </router-link>
                  </div>
                  <div class="col-sm">
                    <div v-if="item.report.report_attachments_xls_file">
                      <a :href="'/frontend-api/report/' + item.report.report_longid + '/_/' + item.report.report_attachments_xls_file + '/' + item.report.report_attachments_xls_filename">
                        <font-awesome-icon icon="download"/>
                        XLS Report
                      </a>
                    </div>
                  </div>
                  <div class="col-sm">
                    <a v-for="image in item.report.report_attachments_images"
                       :key="item.report.report_longid + image.image"
                       :data-lightbox="item.report.report_longid"
                       :href="'/frontend-api/report/' + item.report.report_longid + '/_/' + image.image"
                       class="mr-1">
                      <font-awesome-icon icon="image"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div :id="item.entry.longid" class="card">
              <div class="card-header">
                <font-awesome-icon v-if="item.entry.isComment" class="mr-1" icon="comment-alt"/>
                <font-awesome-icon v-if="item.entry.isManual" class="mr-1" icon="file"/>
                <font-awesome-icon v-if="item.entry.isAssemblyLog" class="mr-1" icon="list-alt"/>
                <font-awesome-icon v-if="item.entry.isCwr" class="mr-1" icon="weight"/>
                <a :href="'#' + item.entry.longid">{{ item.entry.label }} added
                  <span v-if="item.entry.isComment">comment</span>
                  <span v-if="item.entry.isManual">manual</span>
                  <span v-if="item.entry.isAssemblyLog">assembly log</span>
                  <span v-if="item.entry.isCwr">CWR</span>
                </a>
              </div>
              <div class="card-body">
                <div style="white-space:pre-wrap">{{ item.entry.text }}</div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <div class="col-6">
                    <div v-if="item.entry.filename">
                      <a :href="'/frontend-api/entry/' + item.entry.longid + '/' + item.entry.filename">
                        <font-awesome-icon icon="download"/>
                        {{ item.entry.filename }}
                      </a>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="float-right">
                      {{ item.entry.timestamp }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- moveReport Modal -->
    <b-modal id="move-report" ref="move-report" title="Move Report" @ok="moveReport">
      <b-alert :show="!currentModalValid" variant="warning">
        Please select a destination report
      </b-alert>
      <div class="form-group row">
        <label class="col-sm-3 control-label">Report Label</label>
        <div class="col-sm-9">
          <label>{{ selectedReport && selectedReport.report_label ? selectedReport.report_label : 'Unlabeled' }}</label>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3 control-label">Destination Base File Serial</label>
        <div class="col-sm-9">
          <b-form-select v-model="selectedMachineSerial">
            <option :value="null" disabled>-- Please select an option --</option>
            <option v-for="(otherSerial, index) in machine.otherSerials" :key="index" :value="otherSerial.serial">
              {{ otherSerial.serial }}
            </option>
          </b-form-select>
        </div>
      </div>
    </b-modal>
    <!-- commentReport Modal -->
    <b-modal id="comment-report" ref="comment-report" title="Comment Report" @ok="commentReport">
      <b-alert :show="!currentModalValid" variant="warning">
        The comment must not be empty
      </b-alert>
      <div class="form-group row">
        <label class="col-sm-3 control-label">Report Label</label>
        <div class="col-sm-9">
          <b-form-input :placeholder="selectedReport ? selectedReport.report_label: ''" disabled></b-form-input>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3 control-label">Comment</label>
        <div class="col-sm-9">
          <div class="input-group">
            <div class="input-group-prepend"><span class="input-group-text"><font-awesome-icon
                icon="comment-alt"/></span></div>
            <b-form-textarea id="commentText" v-model="addReportComment" class="form-control" name="text" rows="5"
                             style="resize:none"></b-form-textarea>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- add-entry Modal -->
    <b-modal id="add-entry" ref="add-entry" title="Add Life Cycle Entry" @cancel="resetSelections"
             @ok="queryAddLifeCycleEntry">
      <b-alert :show="!currentModalValid" variant="warning">
        Please fill out all mandatory fields
      </b-alert>
      <div class="form-group row">
        <span class="col-sm-2 control-label">Entry Type</span>
        <div class="col-sm-10">
          <b-form-group>
            <b-form-radio-group v-model="addEntryType" stacked>
              <b-form-radio value="comment">Comment</b-form-radio>
              <b-form-radio value="manual">Manual</b-form-radio>
              <b-form-radio value="assemblylog">Assembly Log</b-form-radio>
              <b-form-radio value="cwr">CWR</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 control-label">Text <small>(optional)</small></label>
        <div class="col-sm-10">
          <div class="input-group">
            <div class="input-group-prepend"><span class="input-group-text"><font-awesome-icon
                icon="comment-alt"/></span>
            </div>
            <b-form-textarea id="text" v-model="addEntryText" class="form-control" name="text" rows="5"
                             style="resize:none"></b-form-textarea>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 control-label">File <small
            id="fileOptional">(optional)</small></label>
        <div class="col-sm-10">
          <b-form-file
              v-model="addEntrySelectedFile"
              :state="addEntryFileValidation"
              drop-placeholder="Drop file here..."
              placeholder="Choose a file or drop it here..."
              @change="setSelectedFile"
          ></b-form-file>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>

import { CreateMachineEntryQuery, DetailedMachineInformation } from '@/screencheck_portal_api/src'
import { machinesApi, reportsApi } from '@/backend'
import lightbox from 'lightbox2'
import 'lightbox2/src/css/lightbox.css'

export default {
  name: 'MachineLifeCycle',
  props: {
    machine: {
      type: DetailedMachineInformation
    }
  },
  components: {},
  data () {
    return {
      currentModalValid: true,
      selectedMachineSerial: null,
      selectedReport: null,
      addEntrySelectedFile: null,
      selectedFileContent: null,
      addEntryType: 'comment',
      addEntryText: null,
      addReportComment: null,
    }
  },
  computed: {
    isUserAdmin: function () {
      return this.$store.getters.isUserAdmin
    },
    addEntryFileValidation: function () {
      if (this.addEntryType !== 'comment' && !this.addEntrySelectedFile) {
        return false
      }
      return null
    },

  },
  mounted () {
    lightbox.option({
      'fadeDuration': 0,
      'imageFadeDuration': 0,
      'resizeDuration': 0
    })
  },
  methods: {
    defineReportEventClass: function (user_role) {
      if (user_role === 'restricted') {
        return "card border-warning"
      } else {
        return "card"
      }
    },
    defineReportEventStyle: function (user_role) {
      if (user_role === 'restricted') {
        return "border-width: 3px"
      } else {
        return ""
      }
    },
    openCommentModal: function (report) {
      this.selectedReport = report
      this.addReportComment = report.report_comment
    },
    setSelectedFile (ev) {
      this.addEntrySelectedFile = ev.target.files[0]
    },
    resetSelections: function () {
      this.selectedMachineSerial = null
      this.selectedReport = null
      this.addEntrySelectedFile = null
      this.addEntryType = 'comment'
      this.addEntryText = null
      this.addReportComment = null
      this.currentModalValid = true
    },
    moveReport: function (ev) {
      ev.preventDefault()
      if (!this.selectedReport) {
        console.error('No report selected')
        return
      }
      if (!this.selectedMachineSerial) {
        this.currentModalValid = false
        return
      }
      reportsApi.moveReport(this.selectedReport.report_longid, { machineSerial: this.selectedMachineSerial }, (error => {
        this.resetSelections()
        if (error) {
          console.error('Failed moving report: ' + error)
          return
        }
        this.$emit('machineUpdated')
        this.$refs['move-report'].hide()
      }))
    },
    commentReport: function (ev) {
      ev.preventDefault()
      if (!this.selectedReport) {
        console.error('No report selected')
        return
      }
      if (!this.addReportComment) {
        this.currentModalValid = false
        return
      }
      reportsApi.commentReport(this.selectedReport.report_longid, { comment: this.addReportComment }, (error) => {
        this.resetSelections()
        if (error) {
          console.error('Failed commenting report: ' + error)
          return
        }
        this.$emit('machineUpdated')
        this.$refs['comment-report'].hide()
      })
    },
    queryAddLifeCycleEntry: function () {
      if (this.addEntrySelectedFile) {
        this.addEntrySelectedFile.text().then(function (data) {
          const createQuery = CreateMachineEntryQuery.constructFromObject({
            category: this.addEntryType,
            text: this.addEntryText,
            filename: this.addEntrySelectedFile.name,
            data: data
          })
          this.addLifeCycleEntry(createQuery)
        }.bind(this))
      } else {
        const createQuery = CreateMachineEntryQuery.constructFromObject({
          category: this.addEntryType,
          text: this.addEntryText,
        })
        this.addLifeCycleEntry(createQuery)
      }
    },
    addLifeCycleEntry: function (createQuery) {
      machinesApi.createMachineEntry(this.machine.basefile.serial, createQuery, (error) => {
        this.resetSelections()
        if (error) {
          console.error('Failed creating machine entry: ' + error)
          return
        }
        this.$emit('machineUpdated')
        this.$refs['add-entry'].hide()
      })
    },
  }
}
</script>

<style lang="scss">
div.card:target {
  box-shadow: 0 0 20px yellow;
}
</style>
