/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The FeedDischarge model module.
 * @module model/FeedDischarge
 * @version 0.0.1
 */
class FeedDischarge {
    /**
     * Constructs a new <code>FeedDischarge</code>.
     * @alias module:model/FeedDischarge
     */
    constructor() { 
        
        FeedDischarge.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>FeedDischarge</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/FeedDischarge} obj Optional instance to populate.
     * @return {module:model/FeedDischarge} The populated <code>FeedDischarge</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new FeedDischarge();

            if (data.hasOwnProperty('feedLeftInner')) {
                obj['feedLeftInner'] = ApiClient.convertToType(data['feedLeftInner'], 'Number');
            }
            if (data.hasOwnProperty('feedLeftOuter')) {
                obj['feedLeftOuter'] = ApiClient.convertToType(data['feedLeftOuter'], 'Number');
            }
            if (data.hasOwnProperty('feedRightInner')) {
                obj['feedRightInner'] = ApiClient.convertToType(data['feedRightInner'], 'Number');
            }
            if (data.hasOwnProperty('feedRightOuter')) {
                obj['feedRightOuter'] = ApiClient.convertToType(data['feedRightOuter'], 'Number');
            }
            if (data.hasOwnProperty('dischargeLeftInner')) {
                obj['dischargeLeftInner'] = ApiClient.convertToType(data['dischargeLeftInner'], 'Number');
            }
            if (data.hasOwnProperty('dischargeLeftOuter')) {
                obj['dischargeLeftOuter'] = ApiClient.convertToType(data['dischargeLeftOuter'], 'Number');
            }
            if (data.hasOwnProperty('dischargeRightInner')) {
                obj['dischargeRightInner'] = ApiClient.convertToType(data['dischargeRightInner'], 'Number');
            }
            if (data.hasOwnProperty('dischargeRightOuter')) {
                obj['dischargeRightOuter'] = ApiClient.convertToType(data['dischargeRightOuter'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>FeedDischarge</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>FeedDischarge</code>.
     */
    static validateJSON(data) {

        return true;
    }


}



/**
 * @member {Number} feedLeftInner
 */
FeedDischarge.prototype['feedLeftInner'] = undefined;

/**
 * @member {Number} feedLeftOuter
 */
FeedDischarge.prototype['feedLeftOuter'] = undefined;

/**
 * @member {Number} feedRightInner
 */
FeedDischarge.prototype['feedRightInner'] = undefined;

/**
 * @member {Number} feedRightOuter
 */
FeedDischarge.prototype['feedRightOuter'] = undefined;

/**
 * @member {Number} dischargeLeftInner
 */
FeedDischarge.prototype['dischargeLeftInner'] = undefined;

/**
 * @member {Number} dischargeLeftOuter
 */
FeedDischarge.prototype['dischargeLeftOuter'] = undefined;

/**
 * @member {Number} dischargeRightInner
 */
FeedDischarge.prototype['dischargeRightInner'] = undefined;

/**
 * @member {Number} dischargeRightOuter
 */
FeedDischarge.prototype['dischargeRightOuter'] = undefined;






export default FeedDischarge;

