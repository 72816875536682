/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Deck from './Deck';

/**
 * The MachineFileFixedMedia model module.
 * @module model/MachineFileFixedMedia
 * @version 0.0.1
 */
class MachineFileFixedMedia {
    /**
     * Constructs a new <code>MachineFileFixedMedia</code>.
     * @alias module:model/MachineFileFixedMedia
     */
    constructor() { 
        
        MachineFileFixedMedia.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>MachineFileFixedMedia</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MachineFileFixedMedia} obj Optional instance to populate.
     * @return {module:model/MachineFileFixedMedia} The populated <code>MachineFileFixedMedia</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MachineFileFixedMedia();

            if (data.hasOwnProperty('topDeck')) {
                obj['topDeck'] = Deck.constructFromObject(data['topDeck']);
            }
            if (data.hasOwnProperty('bottomDeck')) {
                obj['bottomDeck'] = Deck.constructFromObject(data['bottomDeck']);
            }
            if (data.hasOwnProperty('upperMidDeck')) {
                obj['upperMidDeck'] = Deck.constructFromObject(data['upperMidDeck']);
            }
            if (data.hasOwnProperty('lowerMidDeck')) {
                obj['lowerMidDeck'] = Deck.constructFromObject(data['lowerMidDeck']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>MachineFileFixedMedia</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MachineFileFixedMedia</code>.
     */
    static validateJSON(data) {
        // validate the optional field `topDeck`
        if (data['topDeck']) { // data not null
          Deck.validateJSON(data['topDeck']);
        }
        // validate the optional field `bottomDeck`
        if (data['bottomDeck']) { // data not null
          Deck.validateJSON(data['bottomDeck']);
        }
        // validate the optional field `upperMidDeck`
        if (data['upperMidDeck']) { // data not null
          Deck.validateJSON(data['upperMidDeck']);
        }
        // validate the optional field `lowerMidDeck`
        if (data['lowerMidDeck']) { // data not null
          Deck.validateJSON(data['lowerMidDeck']);
        }

        return true;
    }


}



/**
 * @member {module:model/Deck} topDeck
 */
MachineFileFixedMedia.prototype['topDeck'] = undefined;

/**
 * @member {module:model/Deck} bottomDeck
 */
MachineFileFixedMedia.prototype['bottomDeck'] = undefined;

/**
 * @member {module:model/Deck} upperMidDeck
 */
MachineFileFixedMedia.prototype['upperMidDeck'] = undefined;

/**
 * @member {module:model/Deck} lowerMidDeck
 */
MachineFileFixedMedia.prototype['lowerMidDeck'] = undefined;






export default MachineFileFixedMedia;

