/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Error from '../model/Error';
import PrintedUser from '../model/PrintedUser';
import TokenRequestQuery from '../model/TokenRequestQuery';
import TokenResponse from '../model/TokenResponse';

/**
* Token service.
* @module api/TokenApi
* @version 0.0.1
*/
export default class TokenApi {

    /**
    * Constructs a new TokenApi. 
    * @alias module:api/TokenApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the getTokens operation.
     * @callback module:api/TokenApi~getTokensCallback
     * @param {String} error Error message, if any.
     * @param {module:model/TokenResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/TokenApi~getTokensCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/TokenResponse}
     */
    getTokens(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = TokenResponse;
      return this.apiClient.callApi(
        '/tokens/data', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the tokenAuth operation.
     * @callback module:api/TokenApi~tokenAuthCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/TokenRequestQuery} tokenRequestQuery The Query containing the needed information to authenticate a token
     * @param {module:api/TokenApi~tokenAuthCallback} callback The callback function, accepting three arguments: error, data, response
     */
    tokenAuth(tokenRequestQuery, callback) {
      let postBody = tokenRequestQuery;
      // verify the required parameter 'tokenRequestQuery' is set
      if (tokenRequestQuery === undefined || tokenRequestQuery === null) {
        throw new Error("Missing the required parameter 'tokenRequestQuery' when calling tokenAuth");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/token/auth', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the tokenOwnDelete operation.
     * @callback module:api/TokenApi~tokenOwnDeleteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/TokenApi~tokenOwnDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    tokenOwnDelete(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/token/own/delete', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the tokenOwnUser operation.
     * @callback module:api/TokenApi~tokenOwnUserCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PrintedUser} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/TokenApi~tokenOwnUserCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PrintedUser}
     */
    tokenOwnUser(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PrintedUser;
      return this.apiClient.callApi(
        '/token/own/user', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the tokenRevoke operation.
     * @callback module:api/TokenApi~tokenRevokeCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/TokenRequestQuery} tokenRequestQuery The Query containing the activationCode to revoke a token
     * @param {module:api/TokenApi~tokenRevokeCallback} callback The callback function, accepting three arguments: error, data, response
     */
    tokenRevoke(tokenRequestQuery, callback) {
      let postBody = tokenRequestQuery;
      // verify the required parameter 'tokenRequestQuery' is set
      if (tokenRequestQuery === undefined || tokenRequestQuery === null) {
        throw new Error("Missing the required parameter 'tokenRequestQuery' when calling tokenRevoke");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/token/revoke', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
