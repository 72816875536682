<template>
  <div>
    <h1 id="headline" class="page-header">ScreenCheck Sensors</h1>
    <b-table :fields="fields" :items="devices" :busy.sync="isBusy" show-empty>
      <template #cell(action)="data">
        <b-button-group size="sm" class="float-right">
          <b-button title="show certificate" @click="showDeviceDetails(data.item)" variant="info" size="sm" class="mr-2">
            <font-awesome-icon icon="file-lines" class="mr-1"/>
            <span>details</span>
          </b-button>
        </b-button-group>
      </template>
      <template #cell(calibrationValidUntil)="data">
        <span v-if="data.item.calibrationValidUntil">
          <b-tag v-if="data.item.calibrationPassed" variant="success" no-remove>passed</b-tag>
          <b-tag v-else variant="danger" no-remove>failed</b-tag>
          valid until {{ new Date(data.item.calibrationValidUntil).toISOString().split('T')[0] }}
        </span>
        <span v-else class="text-muted">Not calibrated</span>
      </template>
      <template #cell(lastUsed)="data">
        <span v-if="data.item.lastUsed">{{ new Date(data.item.lastUsed).toISOString().split('T')[0] }}</span>
        <span v-else class="text-muted">never</span>
      </template>
      <template #cell(sensorName)="data">
        <span class="font-weight-bold">{{ data.item.sensorName }}</span>
      </template>
      <template #cell(lastUser)="data">
        {{ data.item.lastUser ? data.item.lastUser : '-' }}
      </template>
    </b-table>

    <h1 id="headline" class="page-header mt-5">Legacy Tokens</h1>
    <b-table :fields="visibleFields" :items="tokens" :tbody-tr-class="rowClass">
      <template #cell(label)="data">
        <button class="editToken btn btn-outline-dark btn-sm mr-1" type="button" @click="editToken(data.item)">
          <font-awesome-icon icon="edit"/>
        </button>
        <span>{{ data.item.label }}</span>
      </template>
      <template #cell(action)="data">
        <button :disabled="data.item.isCurrent" class="revokeToken btn btn-danger btn-sm float-right"
                title="Current device, use logout" type="button" @click="showRemoveTokenBox(data.item)">
          <font-awesome-icon icon="times" class="mr-1"/>
          <span>revoke</span>
        </button>
      </template>
    </b-table>

    <b-button class="mb-3" variant="outline-dark" @click="toggleDetails">
      {{ detailsOpen ? 'Hide' : 'Show' }} Details
    </b-button>

    <div class="alert alert-info" role="alert">
      <font-awesome-icon icon="info-circle"/>
      You can rename your devices for easy identification by pressing the
      <font-awesome-icon icon="edit"/>
      button.
      If you see devices which you will not use
      again or were authenticated without your permission you can revoke their authentication.
    </div>
    <b-modal id="edit-device-modal" ref="edit-device-modal" :title="'Edit Device [' + this.deviceInfo.longid + ']'" @cancel="cancelEdit" @ok="submit">
      <div id="edit-device" class="form-horizontal">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label" for="label">Label</label>
          <div class="col-sm-9">
            <input id="label" class="form-control" v-model="deviceInfo.label">
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal ref="show-sensor-details-modal" hide-footer title="ScreenCheck Sensor Details">
      <h2>Sensor</h2>
      <p><small class="text-muted">Sensor Name</small><br>{{ deviceInfo.sensorName }}</p>
      <p><small class="text-muted">Firmware</small><br>{{ deviceInfo.sensorFirmware ? deviceInfo.sensorFirmware : '-' }}</p>
      <p><small class="text-muted">MAC Address</small><br>{{ deviceInfo.sensorMac ? deviceInfo.sensorMac : '-' }}</p>
      <h2 class="mt-5">Usage</h2>
      <p><small class="text-muted">Last User</small><br>{{ deviceInfo.lastUser ? deviceInfo.lastUser : '-' }}</p>
      <p><small class="text-muted">Last Usage</small><br>{{ deviceInfo.lastUsed ? new Date(deviceInfo.lastUsed).toISOString().split('T')[0] : '-' }}</p>
      <h2 class="mt-5">Calibration</h2>
      <p><small class="text-muted">Calibration Status</small><br>
        <span v-if="deviceInfo.calibrationValidUntil">
          <b-tag v-if="deviceInfo.calibrationPassed" variant="success" no-remove>passed</b-tag>
          <b-tag v-else variant="danger" no-remove>failed</b-tag>
        </span>
        <span v-else>Not calibrated</span>
      </p>
      <p><small class="text-muted">Calibration
        Date</small><br>{{ deviceInfo.calibrationTimestamp ? new Date(deviceInfo.calibrationTimestamp).toISOString().split('T')[0] : '-' }}</p>
      <p><small class="text-muted">Valid
        Until</small><br>{{ deviceInfo.calibrationValidUntil ? new Date(deviceInfo.calibrationValidUntil).toISOString().split('T')[0] : '-' }}</p>
      <p><small class="text-muted">Calibrated By</small><br>{{ deviceInfo.calibrationUser ? deviceInfo.calibrationUser : '-' }}</p>
      <p><small class="text-muted">Certificate</small><br>
        <b-button title="Download certificate" variant="info" size="sm" :disabled="!deviceInfo.calibrationUuid"
                  :to="{ path: `/frontend-api/calibration/by-uuid/${deviceInfo.calibrationUuid}/certificate` }" target="_blank">
          <font-awesome-icon icon="file-lines" class="mr-1"/>
          <span>open certificate</span>
        </b-button>
      </p>
    </b-modal>
  </div>
</template>
<script>
import { devicesApi } from '@/backend'
import { EditDeviceTokenQuery, RevokeDeviceTokenQuery } from '../screencheck_portal_api/src'

export default {
  name: 'DevicesPage',
  data () {
    return {
      statusText: '',
      devices: [],
      fields: [
        { key: 'sensorName', label: 'Sensor', sortable: true },
        { key: 'calibrationValidUntil', label: 'Calibration Status', sortable: true },
        { key: 'lastUsed', label: 'Last Used', sortable: true },
        { key: 'action', label: '', sortable: false }
      ],
      tokens: null,
      tokensFields: [
        { key: 'label', label: 'Label', sortable: true, visible: true },
        { key: 'longid', label: 'ID', sortable: true, visible: true },
        { key: 'lastUsage', label: 'Last used', sortable: true, visible: true },
        { key: 'expiry', label: 'Expiry', sortable: true, visible: true },
        { key: 'samsungSerial', sortable: true, visible: false },
        { key: 'androidVersion', sortable: true, visible: false },
        { key: 'softwareVersion', sortable: true, visible: false },
        { key: 'sensorName', sortable: true, visible: false },
        { key: 'sensorMac', sortable: true, visible: false },
        { key: 'sensorFirmware', sortable: true, visible: false },
        { key: 'action', label: '', sortable: false, visible: true }
      ],
      detailsOpen: false,
      deviceInfo: {},
      isBusy: false,
    }
  },
  created () {
    this.fetchTokens()
    this.fetchDevices()
  },
  computed: {
    visibleFields () {
      return this.tokensFields.filter(field => field.visible)
    },
    totalRows () {
      return this.devices.length
    }
  },
  methods: {
    showDeviceDetails (device) {
      this.deviceInfo = device
      this.$refs['show-sensor-details-modal'].show()
    },
    toggleDetails: function () {
      let toggleAbleFields = ['samsungSerial', 'androidVersion', 'softwareVersion', 'sensorName', 'sensorMac', 'sensorFirmware']
      this.tokensFields.forEach(field => {
        if (toggleAbleFields.includes(field.key)) {
          field.visible = !field.visible
        }
      })
      this.detailsOpen = !this.detailsOpen
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.is_current) return 'current-token-row'
    },
    fetchTokens: function () {
      devicesApi.getDeviceTokens((error, data) => {
        if (error) {
          console.error('Failed fetching device tokens because: ' + error)
          this.statusText = 'Error transferring device tokens'
          return
        }
        this.tokens = data.tokens
        this.statusText = null
      })
    },
    fetchDevices: function () {
      this.isBusy = true
      devicesApi.getMySensors((error, data) => {
        this.isBusy = false
        if (error) {
          console.error('Failed fetching sensors: ' + error)
          this.statusText = 'Error transferring sensors'
          return
        }
        this.statusText = null
        this.devices = data.devices
      })
    },
    cancelEdit: function () {
      this.deviceInfo.longid = ''
      this.deviceInfo.label = ''
    },
    submit: function () {
      let updateQuery = EditDeviceTokenQuery.constructFromObject({
        tokenLongId: this.deviceInfo.longid.replace(' ', ''),
        tokenLabel: this.deviceInfo.label
      })
      devicesApi.updateDeviceTokenLabel(updateQuery, (error) => {
        if (error) {
          console.error('Failed updating device token label because: ' + error)
          return
        }
        this.fetchTokens()
      })
    },
    showRemoveTokenBox (device) {
      this.$bvModal.msgBoxConfirm(device.label + ' will not be able to access ScreenCheck Portal anymore.', {
        title: 'Revoking device access',
      }).then(value => {
        if (value) {
          let revokeQuery = RevokeDeviceTokenQuery.constructFromObject({
            tokenLongId: device.longid.replace(' ', '')
          })
          devicesApi.revokeDeviceToken(revokeQuery, (error) => {
            if (error) {
              console.error('Failed revoking device token because: ' + error)
              return
            }
            // fetch Data
            this.fetchTokens()
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    editToken: function (row) {
      this.deviceInfo.longid = row.longid
      this.deviceInfo.label = row.label
      this.$refs['edit-device-modal'].show()
    }
  }
}
</script>

<style lang="scss">
</style>
