<template>
  <div>
    <h1 id="headline" class="page-header">All ScreenCheck Sensors</h1>
    <b-table
        :fields="fields"
        :items="devices"
        :per-page="perPage"
        :busy.sync="isBusy"
        :current-page="currentPage"
        show-empty>
      <template #cell(action)="data">
        <b-button-group size="sm" class="float-right">
          <b-button title="show certificate" @click="showDeviceDetails(data.item)" variant="info" size="sm" class="mr-2">
            <font-awesome-icon icon="file-lines" class="mr-1"/>
            <span>details</span>
          </b-button>
        </b-button-group>
      </template>
      <template #cell(calibrationValidUntil)="data">
        <span v-if="data.item.calibrationValidUntil">
          <b-tag v-if="data.item.calibrationPassed" variant="success" no-remove>passed</b-tag>
          <b-tag v-else variant="danger" no-remove>failed</b-tag>
          <span v-if="new Date(data.item.calibrationValidUntil) > new Date()">valid until {{ new Date(data.item.calibrationValidUntil).toISOString().split('T')[0] }}</span>
          <b-tag v-else variant="warning" no-remove>expired</b-tag>
        </span>
        <span v-else class="text-muted">Not calibrated</span>
      </template>
      <template #cell(lastUsed)="data">
        <span v-if="data.item.lastUsed">{{ new Date(data.item.lastUsed).toISOString().split('T')[0] }}</span>
        <span v-else class="text-muted">never</span>
      </template>
      <template #cell(sensorName)="data">
        <span class="font-weight-bold">{{ data.item.sensorName }}</span>
      </template>
      <template #cell(lastUser)="data">
        {{ data.item.lastUser ? data.item.lastUser : '-' }}
      </template>
    </b-table>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <span class="text-muted">
        <b-spinner v-if="isBusy" label="Loading..." small variant="secondary"/>
        <span v-else>{{ totalRows }} results</span>
      </span>
      <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          aria-controls="reports-table"
          class="mb-0"
          first-number
          last-number
          limit="7"
          size="sm"
      ></b-pagination>
      <b-form inline>
        <span class="text-muted">Show</span>
        <b-form-select v-model="perPage" :options="perPageOptions" class="ml-2" size="sm"></b-form-select>
      </b-form>
    </div>
    <b-modal ref="show-sensor-details-modal" hide-footer title="ScreenCheck Sensor Details">
      <h2>Sensor</h2>
      <p><small class="text-muted">Sensor Name</small><br>{{ deviceInfo.sensorName }}</p>
      <p><small class="text-muted">Firmware</small><br>{{ deviceInfo.sensorFirmware ? deviceInfo.sensorFirmware : '-' }}</p>
      <p><small class="text-muted">MAC Address</small><br>{{ deviceInfo.sensorMac ? deviceInfo.sensorMac : '-' }}</p>
      <h2 class="mt-5">Usage</h2>
      <p><small class="text-muted">Last User</small><br>{{ deviceInfo.lastUser ? deviceInfo.lastUser : '-' }}</p>
      <p><small class="text-muted">Last Usage</small><br>{{ deviceInfo.lastUsed ? new Date(deviceInfo.lastUsed).toISOString().split('T')[0] : '-' }}</p>
      <h2 class="mt-5">Calibration</h2>
      <p><small class="text-muted">Calibration Status</small><br>
        <span v-if="deviceInfo.calibrationValidUntil">
          <b-tag v-if="deviceInfo.calibrationPassed" variant="success" no-remove>passed</b-tag>
          <b-tag v-else variant="danger" no-remove>failed</b-tag>
        </span>
        <span v-else>Not calibrated</span>
      </p>
      <p><small class="text-muted">Calibration
        Date</small><br>{{ deviceInfo.calibrationTimestamp ? new Date(deviceInfo.calibrationTimestamp).toISOString().split('T')[0] : '-' }}</p>
      <p><small class="text-muted">Valid
        Until</small><br>{{ deviceInfo.calibrationValidUntil ? new Date(deviceInfo.calibrationValidUntil).toISOString().split('T')[0] : '-' }}</p>
      <p><small class="text-muted">Calibrated By</small><br>{{ deviceInfo.calibrationUser ? deviceInfo.calibrationUser : '-' }}</p>
      <p><small class="text-muted">Certificate</small><br>
        <b-button title="Download certificate" variant="info" size="sm" :disabled="!deviceInfo.calibrationUuid"
                  :to="{ path: `/frontend-api/calibration/by-uuid/${deviceInfo.calibrationUuid}/certificate` }" target="_blank">
          <font-awesome-icon icon="file-lines" class="mr-1"/>
          <span>open certificate</span>
        </b-button>
      </p>
    </b-modal>
  </div>
</template>
<script>
import { devicesApi } from '@/backend'

export default {
  name: 'AllDevicesPage',
  data () {
    return {
      statusText: '',
      devices: [],
      fields: [
        { key: 'sensorName', label: 'Sensor', sortable: true },
        { key: 'calibrationValidUntil', label: 'Calibration Status', sortable: true },
        { key: 'lastUser', label: 'Last User', sortable: true },
        { key: 'lastUsed', label: 'Last Used', sortable: true },
        { key: 'action', label: '', sortable: false }
      ],
      deviceInfo: {},
      perPage: 25,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      isBusy: false,
    }
  },
  mounted () {
    this.fetchDevices()
  },
  computed: {
    totalRows () {
      return this.devices.length
    }
  },
  methods: {
    showDeviceDetails (device) {
      this.deviceInfo = device
      this.$refs['show-sensor-details-modal'].show()
    },
    fetchDevices: function () {
      this.isBusy = true
      devicesApi.getAllSensors((error, data) => {
        this.isBusy = false
        if (error) {
          console.error('Failed fetching sensors: ' + error)
          this.statusText = 'Error transferring sensors'
          return
        }
        this.statusText = null
        this.devices = data.devices
      })
      // Required to use callback
      return null
    },
  }
}
</script>

<style lang="scss">
</style>
